import * as React from "react"

import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Link } from "@reach/router"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Tools for Pickleball Players" />
      <section className="hero is-primary is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Paddle Finder
            </h1>
            <h2 className="subtitle">
              Browse and filter paddles by weight, dimensions, material and anything else necessary to find your
              perfect paddle!
            </h2>
            <Link to={ "paddle-finder" } className="button is-link is-medium">Find Your Paddle</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
